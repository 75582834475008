<div class="mainbox">
    <div class="box" routerLink="/mobile/rate-alert">
        <img class="bellmenu-img" src="../../../assets/bellmenu.png" alt="">
        <p class="rate-para">Rate Alert</p>
    </div>
    <div class="box" routerLink="/mobile/booking">
        <img class="bellmenu-img" src="../../../assets/phone-ringing 1.png" alt="">
        <p class="rate-para">Booking</p>
    </div>
    <div class="box" routerLink="/mobile/margin">
        <img class="bellmenu-img" src="../../../assets/document 1.png" alt="">
        <p class="rate-para">Margin</p>
    </div>
</div>
<div class="mainbox2">
    <div class="boxx" routerLink="/mobile/chart">
        <img class="bellmenu-img" src="../../../assets/rising 1.png" alt="">
        <p class="rate-para">Live Chart</p>
    </div>
    <div class="boxx" routerLink="/mobile/aboutus">
        <img class="bellmenu-img" src="../../../assets/about 1.png" alt="">
        <p class="rate-para">About</p>
    </div>
    <!-- <div class="boxx" routerLink="/mobile/setTimings">
        <img class="bellmenu-img" src="../../../assets/clockImageMobile.png" alt="">
        <p class="rate-para">Set Business Timings</p>
    </div> -->
   
</div>
